import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Pagination, FormControl, Container, Row, Col, Card } from 'react-bootstrap';
import './ReferAndEarn.css'; 
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { BASE_URL } from '../service/baseUrl';

const ReferAndEarn = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10); // Number of users to display per page
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/users/referral-details/`);
        if (response.data.status) {
          setUsers(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const filteredUsers = users.filter(user => {
    const referredUserMatch = user.referred_users.some(referredUser =>
      referredUser.full_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
      user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.mobile_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
      referredUserMatch
    );
  });

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    const visiblePages = () => {
      if (totalPages <= 3) {
        return pageNumbers;
      } else if (currentPage <= 3) {
        return [1, 2, 3, totalPages];
      } else if (currentPage >= totalPages - 2) {
        return [1, totalPages - 2, totalPages - 1, totalPages];
      } else {
        return [1, currentPage - 1, currentPage, currentPage + 1, totalPages];
      }
    };

    return (
      <Pagination className="justify-content-center mt-4">
        <Pagination.Prev
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Pagination.Prev>
        {visiblePages().map((number, index) => (
          <Pagination.Item
            key={index}
            onClick={() => paginate(number)}
            active={currentPage === number}
          >
            {number}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Pagination.Next>
      </Pagination>
    );
  };

  return (
    <div>
      <Header />
      <Container className="mt-5">
        {/* <h2 className="mb-4 text-center refer-earn-title">Refer and Earn Users</h2> */}
        <Row className="justify-content-center mb-4">
          <Col md={6}>
            <FormControl
              type="text"
              placeholder="Search"
              className="mb-4 custom-search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Col>
        </Row>
        <Card className="refer-earn-card">
          <Table striped bordered hover className="refer-earn-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Referred Users</th>
                <th>Wallet Balance</th>
              </tr>
            </thead>
            <tbody>
  {currentUsers.map((user, index) => (
    <tr key={user.id}>
      <td>{indexOfFirstUser + index + 1}</td>
      <td>{user.full_name}</td>
      <td>{user.mobile_number}</td>
      <td>
        {user.referred_users.length > 0 ? (
          user.referred_users.map((referredUser, refIndex) => (
            <span key={refIndex}>
              {referredUser.full_name}
              {refIndex < user.referred_users.length - 1 ? ', ' : ''}
            </span>
          ))
        ) : (
          'No referred users'
        )}
      </td>
      <td>₹{user.wallet_balance}</td>
    </tr>
  ))}
</tbody>

          </Table>
        </Card>
        {renderPagination()}
      </Container>
      <Footer />
    </div>
  );
};

export default ReferAndEarn;
