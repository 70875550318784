import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import axios from 'axios';
import { Container, Row, Col, Card, Spinner, Alert, Modal, Button, Form,Toast, ToastContainer } from 'react-bootstrap';
import Footer from '../Components/Footer';
import EditImageModal from './EditImageModal ';
import { BASE_URL } from '../service/baseUrl';

function Product() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Added delete modal state
  const [currentProduct, setCurrentProduct] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [showEditImageModal, setShowEditImageModal] = useState(false);  // State for EditImageModal
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    axios.get(`${BASE_URL}/products`)
      .then(response => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  const handleProductSelect = (e) => {
    setSelectedProduct(e.target.value);
  };

  const handleShowAddModal = () => {
    setFormData({ name: '', description: '', price: '' });
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => setShowAddModal(false);

  const handleShowEditModal = (product) => {
    setCurrentProduct(product);
    setFormData({
      name: product.name,
      description: product.description,
      price: product.price,
    });
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => setShowEditModal(false);

  const handleShowImageModal = () => setShowImageModal(true);

  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setImagePreview(null);
    setImageFile(null);
  };

  const handleShowDeleteModal = (product) => {
    setCurrentProduct(product);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
  
    if (file.size > 1048576) {
      setToastMessage('File size exceeds 1 MB. Please select a smaller file.');
      setShowToast(true);
      setImageFile(null);
      setImagePreview(null);
    } else {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
      setToastMessage('File selected successfully.');
      setShowToast(true);
    }
  };
  
  

  const handleAddProduct = async () => {
    try {
      const productResponse = await axios.post(`${BASE_URL}/products/`, formData);

      setProducts([...products, productResponse.data]);
      handleCloseAddModal();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEditProduct = async () => {
    try {
      const productResponse = await axios.patch(`${BASE_URL}/products/${currentProduct.id}/`, formData);

      setProducts(products.map(p => p.id === currentProduct.id ? productResponse.data : p));
      handleCloseEditModal();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeleteProduct = async () => {
    try {
      await axios.delete(`${BASE_URL}/products/${currentProduct.id}/`);

      setProducts(products.filter(p => p.id !== currentProduct.id));
      handleCloseDeleteModal();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleAddImage = async () => {
    if (!selectedProduct || !imageFile) {
      setError('Please select a product and upload an image.');
      return;
    }

    const formData = new FormData();
    formData.append('product', selectedProduct);
    formData.append('image', imageFile);

    try {
      await axios.post(`${BASE_URL}/product-images/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setError(null);
      handleCloseImageModal();
    } catch (err) {
      setError(err.message);
    }
  };
  const handleShowEditImageModal = (product) => {
    setCurrentProduct(product);
    setShowEditImageModal(true);
  };
  const handleImageUpdated = () => {
    // Logic to update the product list or refresh the image in the card after image edit
    axios.get(`${BASE_URL}/products`)
      .then(response => {
        setProducts(response.data);
      })
      .catch(err => {
        setError(err.message);
      });
  };

  if (loading) return (
    <Container className="text-center mt-5">
      <Spinner animation="border" />
    </Container>
  );

  if (error) return (
    <Container className="text-center mt-5">
      <Alert variant="danger">Error: {error}</Alert>
    </Container>
  );
  const handleEditImage = async () => {
    if (!selectedProduct || !imageFile) {
      setError('Please select a product and upload an image.');
      return;
    }

    const formData = new FormData();
    formData.append('product', selectedProduct);
    formData.append('image', imageFile);

    try {
      await axios.put(`${BASE_URL}/product-images/${selectedProduct}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setError(null);
      handleCloseImageModal();
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return (
    <Container className="text-center mt-5">
      <Spinner animation="border" />
    </Container>
  );

  if (error) return (
    <Container className="text-center mt-5">
      <Alert variant="danger">Error: {error}</Alert>
    </Container>
  );

  return (
    <div>
      <Header />
      <ToastContainer position="top-end" className="p-3">
  <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg="warning">
    <Toast.Header>
      <strong className="me-auto">Notification</strong>
    </Toast.Header>
    <Toast.Body>{toastMessage}</Toast.Body>
  </Toast>
</ToastContainer>

      <Container className="mt-5">
        <Button variant="primary" onClick={handleShowAddModal} className="mb-4">
          Add Product
        </Button>
        <Button variant="primary" onClick={handleShowImageModal} className="mb-4 ms-2">
          Add Image
        </Button>
        <Row>
          {products.length === 0 ? (
            <Col className="text-center">
              <p>No products available.</p>
            </Col>
          ) : (
            products.map(product => (
              <Col md={4} key={product.id} className="mb-4">
                <Card>
                  <Card.Img style={{height:'300px'}} variant="top" src={product.images[0]?.image} />
                  <Card.Body>
                    <Card.Title>{product.name}</Card.Title>
                    <Card.Text>
                      {product.description || 'No description available.'}
                    </Card.Text>
                    <Card.Text>
                      <strong>Price:</strong> {product.price}
                    </Card.Text>
                    <Card.Text>
                      <small className="text-muted">Created At: {new Date(product.created_at).toLocaleDateString()}</small>
                    </Card.Text>
                    <Button variant="warning" onClick={() => handleShowEditModal(product)}>
                      Edit
                    </Button>
                    <Button variant="info" onClick={() => handleShowEditImageModal(product)} className="ml-2 ms-2">
                      Edit Image
                    </Button>
                    <Button variant="danger" onClick={() => handleShowDeleteModal(product)} className="ml-2 ms-2">
                      Delete
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>

        {/* Add Product Modal */}
        <Modal show={showAddModal} onHide={handleCloseAddModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formProductName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter product name"
                  name="name"
                  value={formData.name}
                  onChange={handleFormChange}
                />
              </Form.Group>
              <Form.Group controlId="formProductDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter product description"
                  name="description"
                  value={formData.description}
                  onChange={handleFormChange}
                />
              </Form.Group>
              <Form.Group controlId="formProductPrice">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter product price"
                  name="price"
                  value={formData.price}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAddModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddProduct}>
              Add Product
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Edit Product Modal */}
        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formEditProductName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter product name"
                  name="name"
                  value={formData.name}
                  onChange={handleFormChange}
                />
              </Form.Group>
              <Form.Group controlId="formEditProductDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter product description"
                  name="description"
                  value={formData.description}
                  onChange={handleFormChange}
                />
              </Form.Group>
              <Form.Group controlId="formEditProductPrice">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter product price"
                  name="price"
                  value={formData.price}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleEditProduct}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the product "{currentProduct?.name}"?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteProduct}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Image Modal */}
        <Modal show={showImageModal} onHide={handleCloseImageModal}>
          <Modal.Header closeButton>
            <Modal.Title>{imagePreview ? 'Edit Image' : 'Add Image'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formProductSelect">
                <Form.Label>Select Product</Form.Label>
                <Form.Control as="select" value={selectedProduct} onChange={handleProductSelect}>
                  <option value="">Select a product</option>
                  {products.map(product => (
                    <option key={product.id} value={product.id}>{product.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formImageUpload">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} />
                {imagePreview && <img src={imagePreview} alt="Preview" className="mt-3 img-fluid" />}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseImageModal}>
              Close
            </Button>
            <Button variant="primary" onClick={imagePreview ? handleEditImage : handleAddImage}>
              {imagePreview ? 'Save Changes' : 'Add Image'}
            </Button>
          </Modal.Footer>
        </Modal>
        {currentProduct && (
          <EditImageModal
            show={showEditImageModal}
            handleClose={() => setShowEditImageModal(false)}
            productId={currentProduct.id}
            onImageUpdated={handleImageUpdated}
          />
        )}
      </Container>
      <Footer />
    </div>
  );
}

export default Product;
