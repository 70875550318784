import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';  // Importing CSS for styling
import { BASE_URL } from '../service/baseUrl';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/adminlogin/`, {
        username,
        password,
      });
      console.log('API response:', response.data); // Log the entire response for debugging

      if (response.status === 200 && response.data.detail === "Login successful") {
        // Handle successful login
        console.log('Login successful!');
        
        // Save user ID to local storage
        const userId = response.data.id;
        localStorage.setItem('adminID', userId);
        
        // Navigate to the home page
        navigate('/');
      } else {
        // Handle login failure
        setError('Invalid username or password');
      }
    } catch (error) {
      // Handle error during the API call
      console.error('Error during API call:', error);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Admin Login</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
