import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import Home from './Pages/Home';
import Winner from './Pages/Winner';
import Gallery from './Pages/Gallery';
import Login from './Pages/Login';
import Coupon from './Pages/Coupon';
import Refer from './Pages/Refer';
import Product from './Pages/Product';




function App() {
  // const location = useLocation();

  const isAdminLoggedIn = () => {
    const adminToken = localStorage.getItem("adminID");
    return adminToken;
  };

  const AdminRoute = ({ element }) => {
    return isAdminLoggedIn() ? element : <Navigate to="/login" />;
  };

  return (
    <div className="App">
      <Routes>
        <Route path='/Login' element={<Login></Login>} ></Route>
        <Route path="/" element={<AdminRoute element={<Home />} />} />
        <Route path="/winner" element={<AdminRoute element={<Winner />} />} />
        <Route path="/Gallery" element={<AdminRoute element={<Gallery />} />} />

        <Route path="/Coupon" element={<AdminRoute element={<Coupon />} />} />
        <Route path="/refer" element={<AdminRoute element={<Refer />} />} />
        <Route path="/product" element={<AdminRoute element={<Product />} />} />


      </Routes>
    </div>
  );
}

export default App;
