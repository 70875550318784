import React, { useState } from 'react';
import { Modal, Button, Form, Alert, Toast } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../service/baseUrl';

function EditImageModal({ show, handleClose, productId, onImageUpdated }) {
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleEditImage = async () => {
    if (!imageFile) {
      setToastMessage('Please upload an image.');
      setToastVariant('danger');
      setShowToast(true);
      return;
    }

    // Check if the image size exceeds 1MB
    if (imageFile.size > 1048576) { // 1MB = 1048576 bytes
      setToastMessage('File size should not exceed 1 MB.');
      setToastVariant('danger');
      setShowToast(true);
      return;
    }

    const formData = new FormData();
    formData.append('product', productId);
    formData.append('image', imageFile);

    try {
      await axios.put(`${BASE_URL}/product-images/${productId}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setToastMessage('Image updated successfully!');
      setToastVariant('success');
      setShowToast(true);
      onImageUpdated();  // Notify parent about the update
      handleClose();  // Close modal
    } catch (err) {
      setToastMessage(`Error: ${err.message}`);
      setToastVariant('danger');
      setShowToast(true);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group controlId="formImageUpload">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
              {imagePreview && <img src={imagePreview} alt="Preview" className="mt-3 img-fluid" />}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditImage}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Toast for notifications */}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        className={`bg-${toastVariant} text-white`}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 9999 }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </>
  );
}

export default EditImageModal;
