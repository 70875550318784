import React, { useEffect, useState } from 'react';
import './Coupon.css'; // Importing CSS for styling
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Button, Modal, Form } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import couponImage from './Group 411.png'; // Importing the image
import { BASE_URL } from '../service/baseUrl';

const CouponViewer = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [couponsPerPage] = useState(10); // Number of coupons to display per page
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newAmount, setNewAmount] = useState('');

  useEffect(() => {
    fetch(`${BASE_URL}/coupons/`)
      .then(response => response.json())
      .then(data => {
        // Sort coupons by created_at date in descending order
        const sortedCoupons = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setCoupons(sortedCoupons);
        setLoading(false);
        console.log(sortedCoupons);
      })
      .catch(error => {
        setLoading(false);
      });
  }, []);

  const filterCoupons = () => {
    return coupons.filter(coupon => {
      const couponDate = new Date(coupon.created_at);
      const filterByDate =
        (!startDate || couponDate >= new Date(startDate)) &&
        (!endDate || couponDate <= new Date(endDate));
      const filterBySearchTerm =
        searchTerm === '' ||
        coupon.full_name.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        coupon.amount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        coupon.coupon_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
        coupon.mobile_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
        coupon.created_at.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (coupon.is_winner ? 'yes' : 'no').includes(searchTerm.toLowerCase());
      return filterByDate && filterBySearchTerm;
    });
  };

  const filteredCoupons = filterCoupons();
  const totalPages = Math.ceil(filteredCoupons.length / couponsPerPage);

  const indexOfLastCoupon = currentPage * couponsPerPage;
  const indexOfFirstCoupon = indexOfLastCoupon - couponsPerPage;
  const currentCoupons = filteredCoupons.slice(indexOfFirstCoupon, indexOfLastCoupon);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); 
  };

  const handleStartDateChange = event => {
    setStartDate(event.target.value);
    setCurrentPage(1);
  };

  const handleEndDateChange = event => {
    setEndDate(event.target.value);
    setCurrentPage(1);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    const visiblePages = () => {
      if (totalPages <= 3) {
        return pageNumbers;
      } else if (currentPage <= 3) {
        return [1, 2, 3, totalPages];
      } else if (currentPage >= totalPages - 2) {
        return [1, totalPages - 2, totalPages - 1, totalPages];
      } else {
        return [1, currentPage - 1, currentPage, currentPage + 1, totalPages];
      }
    };

    return (
      <div className="pagination">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {visiblePages().map((number, index) => (
          <button
            key={index}
            onClick={() => paginate(number)}
            className={currentPage === number ? 'active' : ''}
          >
            {number}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };

  const handlePrint = async () => {
    const doc = new jsPDF();
    const imgWidth = 50;
    const imgHeight = 30; 
    const margin = 10; 
    const gridSize = imgWidth + margin;
    
    let x = margin;
    let y = margin;
    let imagesPerPage = 0;
  
    const drawGridAfterImage = () => {
      // Draw vertical grid line after the image
      doc.setDrawColor(200, 200, 200); // Light gray color for grid lines
      doc.line(x + imgWidth + margin - 2, y, x + imgWidth + margin - 2, y + imgHeight + margin + 20);
  
      // Draw horizontal grid line below the image
      doc.line(x - 2, y + imgHeight + margin, x + imgWidth + margin, y + imgHeight + margin);
    };
  
    coupons.forEach((coupon, index) => {
      if (imagesPerPage >= 15) {
        doc.addPage();
        x = margin;
        y = margin;
        imagesPerPage = 0;
      }
  
      doc.addImage(couponImage, 'PNG', x, y, imgWidth, imgHeight);
      doc.setTextColor(255, 255, 255); // Set text color to white
  
      // Set font size for coupon number
      doc.setFontSize(12);
      const textX = x + imgWidth - 25; // Adjust the x-coordinate for alignment inside and to the right side of the image
      doc.text(`#${coupon.coupon_number}`, textX, y + 10);
  
      // Set font size for the rest of the text
      doc.setFontSize(10);
      doc.text(`${coupon.full_name}`, textX, y + 15);
      doc.text(`${coupon.mobile_number}`, textX, y + 20);
  
      drawGridAfterImage(); // Draw grid lines after each image
  
      x += imgWidth + margin;
  
      if (x + imgWidth + margin > doc.internal.pageSize.width) {
        x = margin;
        y += imgHeight + margin + 20;
      }
  
      imagesPerPage++;
    });
  
    doc.save('coupons.pdf');
  };

  const handleDelete = () => {
    fetch(`${BASE_URL}/delete_all_coupons/`, {
      method: 'DELETE',
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setCoupons([]);
        setShowDeleteModal(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setShowDeleteModal(false);
      });
  };

  const handleEdit = () => {
    setShowEditModal(true);
  };

  const handleSaveEdit = () => {
    fetch(`${BASE_URL}/edit_coupon_amount/1/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount: newAmount }),
    })
      .then(response => response.json())
      .then(data => {
        const updatedCoupons = coupons.map(coupon => ({
          ...coupon,
          amount: newAmount,
        }));
        setCoupons(updatedCoupons);
        setShowEditModal(false);
        setNewAmount('');
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <Header />
      <div className="coupon-viewer">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="date-filter">
            <div className="date-input">
              <label htmlFor="start-date">Start Date:</label>
              <input
                id="start-date"
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="date-input">
              <label htmlFor="end-date">End Date:</label>
              <input
                id="end-date"
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
            <Button className='btn btn-warning text-white m-auto del1' onClick={handleEdit}><b>Edit Amount</b></Button>
            <Button className='btn btn-danger m-auto del1' onClick={() => setShowDeleteModal(true)}><b>Delete</b></Button>
            <Button className='print' onClick={handlePrint}><b>Print</b></Button>
          </div>
          <div style={{display:'inline-flex',marginTop:'5%'}}>
          <Button className='btn btn-warning text-white m-auto del2' onClick={handleEdit}><b>Edit Amount</b></Button>
            <Button className='btn btn-danger m-auto del2 ms-3' onClick={() => setShowDeleteModal(true)}>Delete</Button>
            <Button className='print2' onClick={handlePrint}><b>Print</b></Button>
          </div>
        </div>
        <div className="table-container">
          {loading ? (
            <div className="loading">Loading...</div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Amount</th>
                  <th>Quantity</th>
                  <th>Coupon Number</th>
                  <th>Mobile</th>
                  <th>Created At</th>
                  <th>Winner</th>
                </tr>
              </thead>
              <tbody>
                {currentCoupons.map(coupon => (
                  <tr key={coupon.id}>
                    <td>{coupon.full_name}</td>
                    <td>{coupon.amount}</td>
                    <td>{coupon.quantity}</td>
                    <td>{coupon.coupon_number}</td>
                    <td>{coupon.mobile_number}</td>
                    <td>{new Date(coupon.created_at).toLocaleString()}</td>
                    <td>{coupon.is_winner ? 'Yes' : 'No'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {renderPagination()}
      </div>
      <Footer />
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete all coupons?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Coupon Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formAmount">
              <Form.Label>New Amount</Form.Label>
              <Form.Control
                type="number"
                value={newAmount}
                onChange={e => setNewAmount(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CouponViewer;
