import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import logo from './IMG_6792.PNG'
function Header() {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('adminID');
    navigate('/login'); // Replace '/login' with the route you want to navigate to after logout
    setShowLogoutModal(false);
  };

  const handleShowLogoutModal = () => setShowLogoutModal(true);
  const handleCloseLogoutModal = () => setShowLogoutModal(false);

  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="navbarr">
        <Container>
          <Navbar>
            <Container>
              <Navbar.Brand href="/">
                <img
                  alt=""
                  src={logo}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                />{' '}
                <span style={{ color: 'white' }}>Hope</span>
              </Navbar.Brand>
            </Container>
          </Navbar>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/Coupon" style={{ color: 'white' }}>Coupons</Nav.Link>
              <Nav.Link href="/Gallery" style={{ color: 'white' }}>Gallery</Nav.Link>
              <Nav.Link href="/winner" style={{ color: 'white' }}>Winner</Nav.Link>
              <Nav.Link href="/refer" style={{ color: 'white' }}>Refer</Nav.Link>
              <Nav.Link href="/product" style={{ color: 'white' }}>Product</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link style={{ color: 'white' }} onClick={handleShowLogoutModal}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal show={showLogoutModal} onHide={handleCloseLogoutModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLogoutModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Header;
