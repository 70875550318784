import { BASE_URL } from "./baseUrl";
import { commonApi } from "./commonApi";




export const userview = async (body) => {
    return await commonApi("GET", `${BASE_URL}/users/`, body, "");
  };
  export const total_users = async (body) => {
    return await commonApi("GET", `${BASE_URL}/total_users/`, body, "");
  };

  export const total_coupons = async (body) => {
    return await commonApi("GET", `${BASE_URL}/coupons-total/`, body, "");
  };
  export const total_amount = async (body) => {
    return await commonApi("GET", `${BASE_URL}/total_amount_generated/`, body, "");
  };
