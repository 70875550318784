// src/Footer.js
import React from 'react';
import './Footer.css';  

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 Hope. All rights reserved.</p>
        <p style={{display:'flex',justifyContent:'center'}}>
          <p>Privacy Policy </p> |  
          <p> Terms of Service</p>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
