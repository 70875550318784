import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Winner.css';
import Header from '../Components/Header';
import ScratchCard from 'react-scratchcard';
import coupon from '../3dvector_stockphoto_felizdisenofelicities_17.jpg';
import Footer from '../Components/Footer';
import coupon2 from './Group 411.png';
import { BASE_URL } from '../service/baseUrl';

function Winner() {
  const [showScratchCard, setShowScratchCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [couponData, setCouponData] = useState('');
  const [Data, setdata] = useState('');
  const [amount, setAmount] = useState('');
  const [hasWinner, setHasWinner] = useState(false);
  const [mobile, setMobile] = useState('');
  const [name, setName] = useState('');
  const [showEffect, setShowEffect] = useState(false); 

  const settings = {
    width: 330,
    height: 200,
    image: coupon,
    finishPercent: 50,
    onComplete: () => console.log('Scratch card completed!'),
  };

  useEffect(() => {
    axios.get(`${BASE_URL}/winner/`)
      .then(response => {
        if (response.data.length > 0) {
          setCouponData(response.data[0].coupon_number); 
          setAmount(response.data[0].amount);
          setdata(response.data[0]);
          setShowScratchCard(false);
          setHasWinner(true);
        }
      })
      .catch(error => {
        console.error('Error fetching the winner:', error);
      });
  }, []);

  const handleButtonClick = () => {
    setIsLoading(true);
    axios.get(`${BASE_URL}/random_coupon/`)
      .then(response => {
        setCouponData(response.data.coupon_number);
        setMobile(response.data.mobile_number);
        setName(response.data.full_name);
        setAmount(response.data.amount);

        setTimeout(() => {
          setIsLoading(false);
          setShowScratchCard(true);
          
          // Trigger the effect after the loader disappears
          setTimeout(() => {
            setShowEffect(true);
          }, 0);
        }, 5000);
      })
      .catch(error => {
        console.error('Error fetching the coupon:', error);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Header />
      <div className='win'>
        {!hasWinner && !isLoading && (
          <button className='byun' onClick={handleButtonClick}>Winner</button>
        )}
      </div>
      <div>
        {isLoading && (
          <div className="loader"></div>
        )}
        {showScratchCard && (
          <div className={`coup ${showEffect ? 'show' : ''}`}>
            <ScratchCard {...settings}>
              <img style={{ width: '330px', height: '200px' }} src={coupon2} alt="" />
              <p className='coupon'>#{couponData}</p>
              <div className='coupon4'>
                <p>{name}</p>
                <p>{mobile}</p>
              </div>
            </ScratchCard>
          </div>
        )}
        {!showScratchCard && hasWinner && (
          <div className='coup2'>
            <img className='winnerimg' src={coupon2} alt="" />
            <p className='coupon2'>#{couponData} < hr className='container w-50 underline'/></p>
            <div className='coupon3'>
              <p>{Data.user.full_name}</p>
              <p>{Data.user.mobile_number}</p>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Winner;
