import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import { BASE_URL } from '../service/baseUrl';

function Gallery() {
  const [images, setImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState('');
  const [editFile, setEditFile] = useState(null);
  const [editPreviewURL, setEditPreviewURL] = useState('');
  const [editImageId, setEditImageId] = useState(null); 
  const [deleteImageId, setDeleteImageId] = useState(null);
  
  const previewRef = useRef(null);

  useEffect(() => {
    fetch(`${BASE_URL}/gallery/`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched images:', data);
        if (Array.isArray(data)) {
          setImages(data);
        } else {
          console.error('Expected an array of images but received:', data);
          setImages([]);
        }
      })
      .catch(error => {
        console.error('Error fetching images:', error);
        setImages([]);
      });
  }, []);

  const handleEdit = (id) => {
    const imageToEdit = images.find(image => image.id === id);
    if (imageToEdit) {
      setEditImageId(id); 
      setEditPreviewURL(imageToEdit.image); 
    }

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 1048576) { // 1MB = 1048576 bytes
          toast.error('File size should not exceed 1 MB.');
          return;
        }
        setEditFile(file);
        setEditPreviewURL(URL.createObjectURL(file));
        previewRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
    fileInput.click();
  };

  const handleUpdate = () => {
    if (!editFile || !editImageId) return;
  
    const formData = new FormData();
    formData.append('image', editFile);
    fetch(`${BASE_URL}/gallery/${editImageId}/`, {
      method: 'PUT',
      body: formData,
    })
      .then(response => response.json())
      .then(updatedImage => {
        setImages(images.map(image =>
          image.id === editImageId ? updatedImage : image
        ));
        setEditFile(null);
        setEditPreviewURL('');
        setEditImageId(null); 
        toast.success('Image updated successfully!');
      })
      .catch(error => {
        console.error('Error updating image:', error);
        toast.error('Error updating image!');
      });
  };

  const handleDelete = (id) => {
    fetch(`${BASE_URL}/gallery/${id}/`, {
      method: 'DELETE',
    })
      .then(() => {
        setImages(images.filter(image => image.id !== id));
        toast.success('Image deleted successfully!');
        setDeleteImageId(null);
      })
      .catch(error => {
        console.error('Error deleting image:', error);
        toast.error('Error deleting image!');
      });
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1048576) { // 1MB = 1048576 bytes
        toast.error('File size should not exceed 1 MB.');
        return;
      }
      setSelectedFile(file);
      setPreviewURL(URL.createObjectURL(file));
      previewRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleUpload = () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('image', selectedFile);
    fetch(`${BASE_URL}/gallery/`, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(newImage => {
        setImages([...images, newImage]);
        setSelectedFile(null);
        setPreviewURL('');
        toast.success('Image uploaded successfully!');
      })
      .catch(error => {
        console.error('Error uploading image:', error);
        toast.error('Error uploading image!');
      });
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setPreviewURL('');
  };

  const handleEditCancel = () => {
    setEditFile(null);
    setEditPreviewURL('');
    setEditImageId(null);
  };

  return (
    <div>
      <Header />
      <div className="container mt-3">
        <div className="row mb-4">
          <div className="col">
            <label className="btn btn-primary">
              <FontAwesomeIcon icon={faUpload} /> Select Image
              <input type="file" accept="image/*" onChange={handleFileSelect} style={{ display: 'none' }} />
            </label>
          </div>
        </div>
        <div ref={previewRef}>
          {previewURL && (
            <div className="row mb-4">
              <div className="col">
                <div className="card w-25">
                  <img src={previewURL} className="card-img-top" alt="Selected preview" height="200" />
                  <div className="card-body">
                    <button className="btn btn-primary me-2" onClick={handleUpload}>
                      Upload Image
                    </button>
                    <button className="btn btn-secondary" onClick={handleCancel}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {editPreviewURL && (
            <div className="row mb-4">
              <div className="col">
                <div className="card w-25">
                  <img src={editPreviewURL} className="card-img-top" alt="Edit preview" height="200" />
                  <div className="card-body">
                    <button className="btn btn-primary me-2" onClick={handleUpdate}>
                      Update Image
                    </button>
                    <button className="btn btn-secondary" onClick={handleEditCancel}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          {images.map(image => (
            <div key={image.id} className="col-sm-3 mb-4">
              <div className="card">
                <img src={image.image} className="card-img-top" alt={image.alt || `Image ${image.id}`} height="200" />
                <div className="card-body">
                  <button className="btn btn-light me-2" onClick={() => handleEdit(image.id)}>
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </button>
                  <button className="btn btn-light" onClick={() => setDeleteImageId(image.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
      <ToastContainer />
      <Modal show={deleteImageId !== null} onHide={() => setDeleteImageId(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this image?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteImageId(null)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => handleDelete(deleteImageId)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Gallery;
