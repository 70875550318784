import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import { Card, Modal, Button } from 'react-bootstrap';
import CountUp from 'react-countup';
import axios from 'axios';
import { total_users, total_coupons, total_amount, userview } from '../service/allApi';
import './Home.css';
import Footer from '../Components/Footer';
import { BASE_URL } from '../service/baseUrl';
import ReactPaginate from 'react-paginate';

function Home() {
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userCoupons, setUserCoupons] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalCoupons, setTotalCoupons] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersResponse = await total_users();
        setTotalUsers(usersResponse.data.total_users);

        const couponsResponse = await total_coupons();
        setTotalCoupons(couponsResponse.data.total_coupons);

        const amountResponse = await total_amount();
        setTotalAmount(amountResponse.data.total_amount_generated);

        const userDataResponse = await userview();
        setTableData(userDataResponse.data);
        setFilteredData(userDataResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filtered = tableData.filter(user =>
      user.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.mobile_number.toLowerCase().includes(searchQuery.toLowerCase())||
      user.referral_code.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrentPage(0); // Reset to first page on search
  }, [searchQuery, tableData]);

  const handleUsernameClick = async (user) => {
    setSelectedUser(user);
    try {
      const response = await axios.get(`${BASE_URL}/user_coupons/${user.id}/`);
      setUserCoupons(response.data);
    } catch (error) {
      console.error("Error fetching coupon details:", error);
    }
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedUser(null);
    setUserCoupons([]);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <div style={{ fontFamily: 'Rubik, sans-serif' }}>
      <Header />
     
      <div className="card-container mt-3">
        <Card className="custom-card">
          <Card.Body>
            <Card.Title className="card-title">Total Users</Card.Title>
            <Card.Text className="card-text">
              <CountUp end={totalUsers} duration={2} />
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="custom-card">
          <Card.Body>
            <Card.Title className="card-title">Total Coupon</Card.Title>
            <Card.Text className="card-text">
              <CountUp end={totalCoupons} duration={2} />
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="custom-card">
          <Card.Body>
            <Card.Title className="card-title">Amount Collected</Card.Title>
            <Card.Text className="card-text">
              <CountUp end={totalAmount} duration={2} />
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="search-container2 ">
        <input
          type="text"
         className="search-input2"
          placeholder="Search ..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="table-container container hopetable">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <>
            <table className="content-table">
              <thead>
                <tr>
                  <th>Sl no</th>
                  <th>Full Name</th>
                  <th>Mobile Number</th>
                  <th>Referal Code</th>
                  <th>Wallet Balance</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.length > 0 ? (
                  currentPageData.map((user, index) => (
                    <tr key={user.id}>
                      <td>{offset + index + 1}</td>
                      <td onClick={() => handleUsernameClick(user)} style={{ cursor: 'pointer' }}>
                        {user.full_name}
                      </td>
                      <td>{user.mobile_number}</td>
                      <td>{user.referral_code}</td>
                      <td>{user.wallet_balance}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="pagination-container mt-2">
              <ReactPaginate
                pageCount={pageCount}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                activeClassName={'active'}
                previousLabel={'Previous'}
                nextLabel={'Next'}
              />
            </div>
          </>
        )}
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Coupons of {selectedUser?.full_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userCoupons.length > 0 ? (
            <div className="coupon-list">
              {userCoupons.map(coupon => (
                <div key={coupon.id} className="coupon-item">
                  <div className="coupon-number">Coupon Number: {coupon.coupon_number}</div>
                  <div className="coupon-number">Amount: {coupon.amount}</div>
                  <div className="coupon-number">Created at: {coupon.created_at}</div>
                </div>
              ))}
            </div>
          ) : (
            <p>No coupons found for this user.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </div>
  );
}

export default Home;
